<script setup>
  import { defineProps, ref } from 'vue';
  import { Format } from '@/format';

  const isOpen = ref(false);
  const props = defineProps(['data']);

  function toggleAccordion() {
    isOpen.value = !isOpen.value;
  }
</script>

<template>
  <hr>

  <div class="order__product-item">
    <div class="order__product-item-header">
      <!-- <img :src="props.data.imageUrl"> -->
      <img class="order__product-item-img" src="https://cdn.shopify.com/s/files/1/0605/8049/5522/products/BOESS_box_blackout_no-sheers_rightAngle_matteBlack_box_f9604884-ab65-4da3-97c6-a2aad0086d6b.jpg?v=1657748429" alt="">

      <div class="order__product-item-title">
        <div>
          <h3 class="heading-3" v-if="props.data.windowName">{{ props.data.windowName }}</h3>
          <h5>{{ props.data.heading }}</h5>
        </div>

        <div class="order__product-item-pricing">
          <div class="order__product">
            <label class="pretext">Price: </label>
            <span>{{ Format.asCurrency(props.data.cost) }}</span>
          </div>
          <div>
            <label class="pretext">Qty: </label>
            <span>{{ props.data.quantity }}</span>
          </div>
          <div>
            <label class="pretext">Total: </label>
            <span>{{ Format.asCurrency(props.data.totalCost) }}</span>
          </div>
        </div>

        <div class="order__product-item-content">
          <div class="accordion__product-header" @click="toggleAccordion">
            <h3 class="pretext color--lavaglas">Configuration Details</h3>
            <div class="accordion__icon"></div>
          </div>

          <transition name="accordion-slide">
            <div v-if="isOpen" class="accordion__product-content top-border">
              <div v-for="option in props.data.configuration" :key="option" class="accordion__product-item-wrapper">
                <label class="accordion__product-item pretext no--margin-b">{{ option.label }}:</label>
                <span class="accordion__product-item ts--callout no--margin-b line-item__fabric">{{ option.value }}</span>
              </div>
            </div>
          </transition>
        </div>

      </div>
    </div>
  </div>
</template>