<script setup>    
  import { ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { Format } from '@/format';  
  import { useBackend } from '@/composables/Backend';   
  import Product from '@/components/Product.vue'; 

  const router = useRouter();
  const route = useRoute();
  const backend = useBackend();
  
  const order = ref({
    id: route.query['id'],
    number: '',
    date: '',
    customer: {
      shipTo: '',
    },
    products: [],
    discount: '',
    discountAmount: '',
    tradeProgramDiscount: '',
    subtotal: '',
    tax: '',
    totalCost: ''
  });
  
  backend.getOrder(order.value.id)
    .then(result => {
      if (result && result.error) {
        // user isn't logged in, redirect to login form
        // TODO: should probably check the status and make sure it's an unauthorized error instead of a not found error
        router.push({ name: 'login' });
      }
      else {
        order.value.number = result.orderNumber;
        order.value.date = result.purchaseDate;        
        order.value.customer.shipTo = result.shippingAddress.replace(/\n/g, '<br>');
        order.value.discount = result.discountAmount;
        order.value.discountAmount = result.discountAmountText;
        order.value.tradeProgramDiscount = result.tradeProgramDiscountText;
        order.value.subtotal = result.subTotalText;
        order.value.tax = result.taxAmountText;
        order.value.totalCost = result.totalCostText;
        //order.value.customer.shipTo = result.shippingAddress;

        result.items.forEach(apiProduct => {
          // TODO: convert to our product data so we can show more user friendly data
          // quote.value.products.push(MapFromQuote.toProductData(apiProduct));
          
          const product = {
            id: apiProduct.productId,
            heading: apiProduct.name,
            windowName: apiProduct.label,
            imageUrl: apiProduct.imageUrl,
            cost: apiProduct.cost,
            quantity: apiProduct.quantity,
            totalCost: apiProduct.cost * apiProduct.quantity,
            configuration: apiProduct.configurationDetails.map(configDetail => {
              return { label: configDetail.label, value: configDetail.content }
            })
          };

          order.value.products.push(product);
        });
      }      
    });      
</script>

<template>
  <div class="container order-view">
    <div class="row">
      <div class="col">
        <div>
          <a href="/my-account">Back to Account</a>
          <h2>Order {{ order.number }}</h2>
        </div>
        
        <div class="card order--card">
          <div class="card__tag-corner">
            <div class="card__tag">Total Paid</div>
          </div>

          <label class="pretext">Date</label>
          <span class="order__date">{{ Format.asDate(order.date) }}</span>
          
          <label class="pretext">Shipping To</label>
          <span class="order__shipping" v-html="order.customer.shipTo"></span>

          <template v-for="product in order.products" :key="product.itemId">
            <product :data="product"></product>
          </template>  

          <hr>
          
          <div class="order__totals">
            <div v-if="order.discount > 0">
              <label class="pretext">Discount: </label>
              <span>- {{ order.discountAmount }}</span>
            </div>

            <div v-if="order.tradeProgramDiscount != ''">
              <label class="pretext">Trade Discount: </label>
              <span>- {{ order.tradeProgramDiscount }}</span>
            </div>

            <div>
              <label class="pretext">Subtotal: </label>
              <span>{{ order.subtotal }}</span>
            </div>

            <div>
              <label class="pretext">Tax: </label>
              <span>{{ order.tax }}</span>
            </div>

            <div>
              <label class="pretext">Total: </label>
              <span>{{ order.totalCost }}</span>
            </div>
          </div>
          <!-- <b-link @click="doAddToCart(quote.id)">Add All Items to Cart →</b-link> -->
        </div>
      </div>
    </div>
  </div>  
</template>